@tailwind base;

html {
  font-family: 'Montserrat', sans-serif;
}

@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor..
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

 .resize-none {
     resize: none;
 }

 .h-50-vh {
     height: 50vh;
 }

 .h-80-vh {
     height: 80vh;
 }

 .checkbox {
     @apply hidden;
 }

 .checkbox + label::before {
     @apply inline-block w-5 h-5 mr-1 -mb-1 border-blue-300 border-2 rounded;
     content: '';
 }

 .checkbox:checked + label::before {
     @apply bg-indigo-500;
     content: '';
 }

 .tab-sticky-top {
     top: 3.2rem;
 }

 .message-box-max {
     max-width: calc(100vw - 2rem);
 }
 .border-transparent-important {
     border-color: transparent !important;
 }
 .text-logo-red {
     color: #D00A2C;
 }
 .border-logo-red,
 .fc-event {
    border-color: #D00A2C !important;
 }
 .bg-logo-red,
 .fc-event, .fc-event-dot {
    background-color: #D00A2C !important;
 }
 .hover\:bg-logo-red:hover {
    background-color: #D00A2C;
 }
 .z-60 {
     z-index: 60;
 }
 .shadow-outline-logo-red {
    box-shadow: 0 0 0 3px rgb(208, 10, 44, 0.5);
 }
 .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255);
    display: table;
    transition: opacity .3s ease;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .modal-container {
    width: 700px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }
  
  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }
  
  .modal-body {
    margin: 20px 0;
  }
  
  .modal-default-button {
    float: right;
  }
  
  .modal-enter {
    opacity: 0;
  }
  
  .modal-leave-active {
    opacity: 0;
  }
  
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }